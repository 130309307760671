@import "variables.module";

.modal {
  &Outer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  &Inner {
    width: 100%;
    max-width: $width-max;
    background-color: $color-white;
    border-radius: 30px 30px 0px 0px;

    .section {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      padding: 0 21px;
      margin: 20px 0 12px;

      font-size: 14px;

      &Buttons {
        align-items: center;
        width: 100%;
        padding: 0 24px;
        margin: 16px 0 12px;
      }

      .saveButton {
        width: 90px;
        height: 32px;
        border-radius: 80px;
        background: $color-black;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &Disabled {
          background: $color-gray;
        }
        > span {
          color: $color-white;
        }
      }

      .sectionTitle {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .likeList {
      height: 85%;
      padding-bottom: 30px;
      overflow-y: scroll;
    }

    .menuButtons {
      padding: 0 18px 40px;

      .menuButton {
        padding: 16px 0;
        cursor: pointer;

        &Delete {
          color: $color-red;
        }
      }

      .menuButton + .menuButton {
        border-top: 1px solid $color-gray-lighter;
      }
    }
  }

  &Like {
    place-self: flex-end;
    justify-self: center;
    height: 55%;
    bottom: 0;
  }

  &Menu {
    min-height: 163px;
    bottom: 0;
    place-self: flex-end;
    justify-self: center;
  }

  &Confirm {
    min-height: 208px;
    width: 100%;
    max-width: 300px;
    border-radius: 16px;

    .section {
      padding: 18px 24px;
      text-align: center;
    }

    .menuButtons {
      border-top: 1px solid $color-gray-lighter;
      text-align: center;
      padding: 0;
    }
  }

  &GridForm {
    place-self: flex-end;
    justify-self: center;
    height: 400px;
    bottom: 0;

    .container {
      padding: 30px 24px 36px;

      h2 {
        color: $color-gray-darker;
        font-size: 12px;
        line-height: 100%;
        margin-bottom: 12px;
      }

      .textInput {
        width: 100%;
        padding-left: 0;
        padding-bottom: 13px;
        border-bottom: 1px solid $color-gray-lighter;
        color: $color-black;

        &::placeholder {
          color: $color-gray;
        }
      }
    }
  }

  &Color {
    height: 400px;

    .colorForm {
      border-radius: 8.331px;
      background-color: $color-black;

      .colorPicker {
        padding: 10px 10px 0;
      }

      .colorInput {
        padding: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      input {
        width: 92px;
        padding: 5px 10px;
        color: $color-gray;
        border-radius: 4px;
        border: 1px solid $color-white;
      }
    }
  }

  &TextLink {
    height: 200px;
  }

  &ImageCard {
    height: 660px;
    max-height: 95%;

    h2 {
      margin-top: 24px;
    }
  }

  &LinkCard {
    height: auto;
    max-height: 95%;

    .container {
      padding: 19px 24px 40px;
    }

    h2 {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }

    .imageUploader {
      &Container {
        height: 180px;
        background-color: #ccc;
        border-radius: 6px;
      }
      .buttons > button.uploadButton {
        width: 100%;
      }
    }

    textarea {
      height: 70px;
      color: $color-black;

      &::placeholder {
        color: $color-gray;
      }
      line-height: 120%;
    }
  }
}

.imageUploader {
  &Container {
    width: 100%;
    height: 300px;
    background-color: #ccc;
    aspect-ratio: 1 / 1;
  }

  .imagePreview {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-lighter-200;

    .fileImage {
      overflow: hidden;
      max-height: 100%;
      position: relative;

      > video {
        width: 100%;
        height: 100%;
      }

      &.layout4x1 {
        width: 280px;
        height: 70px;
      }

      &.layout4x2 {
        width: 280px;
        height: 140px;
      }

      &.layout2x2,
      &.layout4x4,
      &.layoutResize2x2,
      &.layoutResize4x4 {
        width: 280px;
        height: 280px;
      }

      &.layoutResize2x1 {
        width: 280px;
        height: 140px;
      }

      &.layoutResize4x1 {
        width: 280px;
        height: 70px;
      }

      &.layoutResize4x2 {
        width: 280px;
        height: 140px;
      }

      &.layoutResize2x3 {
        width: 186.67px;
        height: 280px;
      }

      &.layoutResize4x3 {
        width: 280px;
        height: 210px;
      }

      &.layoutResize2x4 {
        width: 140px;
        height: 280px;
      }

      &.layoutResize4x4 {
        width: 280px;
        height: 280px;
      }

      &.layoutResize2x5 {
        width: 112px;
        height: 280px;
      }

      &.layoutResize4x5 {
        width: 224px;
        height: 280px;
      }

      &.layoutResize2x6 {
        width: 93.33px;
        height: 280px;
      }

      &.layoutResize4x6 {
        width: 186.67px;
        height: 280px;
      }

      &.layoutResize2x7 {
        width: 80px;
        height: 280px;
      }

      &.layoutResize4x7 {
        width: 160px;
        height: 280px;
      }

      &.layoutResize2x8 {
        width: 70px;
        height: 280px;
      }

      &.layoutResize4x8 {
        width: 140px;
        height: 280px;
      }

      &.layoutThumbnail {
        width: 341px;
        height: 180px;
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;

    > button {
      &.spacerButton {
        width: 42px;
        height: 42px;
      }

      &.uploadButton {
        border: 1px solid $color-black;
        color: $color-black;
        text-align: center;
        background-color: $color-white;
        width: 206px;
        height: 42px;
        border-radius: 80px;
      }

      &.cropButton {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 44px;
        background-color: $color-gray-lighter-200;
      }
    }
  }
}
