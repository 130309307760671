@import "variables.module";

.hidden {
  display: none;
}

.fontSemiBold {
  font-weight: 600;
}

.fontBold {
  font-weight: 700;
}

.heading2Xl {
  font-size: 2.5rem;
  line-height: 1.2;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0;
}

.headingXl {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0;
}

.headingLg {
  font-size: 16px;
  font-weight: 700;
}

.headingMd {
  font-size: 1.2rem;
  line-height: 1.5;
}

.borderCircle {
  border-radius: 9999px;
}

.colorInherit {
  color: inherit;
}

.padding1px {
  padding-top: 1px;
}

.marginTopXL {
  margin-top: 36px;
}

.marginTopLg {
  margin-top: 24px;
}

.marginTopSm {
  margin-top: 10px;
}

.marginTopMin {
  margin-top: 5px;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItem {
  margin: 0 0 1.25rem;
}

.lightText {
  color: $color-gray;
}

.blackText {
  color: $color-black;
}

.whiteText {
  color: $color-white;
}

.widthFull {
  width: 100%;
}

.gridButtonMargin {
  margin-bottom: 65px;
}

.header {
  width: 100%;
  max-width: $width-max;
  height: 52px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  &Front {
    z-index: 2;
  }

  &Fixed {
    position: fixed;
  }

  .nextButton {
    width: 62px;
    height: 29px;
    background-color: $color-white;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;

    &Disabled {
      color: $color-white;
      background-color: $color-gray-lighter-300;
    }
  }

  .nextLink {
    padding: 8px 16px;
    min-width: 70px;

    &Disabled {
      color: $color-gray;
    }
  }

  .hiddenText {
    padding: 8px 16px;
    opacity: 0;
  }

  .editTitle {
    font-size: 18px;
    font-weight: 600;
    padding: 8px 16px;
  }
}

.inputHeader {
  width: 100%;
  max-width: $width-max;
  height: 52px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Item {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > form {
      > input {
        margin-left: 20px;
        font-size: 18px;
        width: 80%;
      }
    }
  }
}

.avatar {
  border-radius: 50%;
  background-color: $color-white;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  //box-shadow: 0 0 0 1px #fff9;
  box-shadow: 0 0 0 1px $color-white;

  img {
    border-radius: 50%;
  }
}

.avatarMain {
  position: relative;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 0 2px #fff;

  img {
    border-radius: 50%;
  }
}

.mainField {
  margin-top: 100px;
  background: $color-bg-white;
  width: 100%;
  max-width: $width-max;
  //min-height: 1000px;
  min-height: calc(100vh - 164px);
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &HeaderFixed {
    margin-top: 164px;
  }
}

.minField {
  background: $color-bg-white;
  width: 100%;
  max-width: $width-max;
  min-height: calc(100vh - 52px);
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minFieldBetween {
  background: $color-bg-white;
  width: 100%;
  max-width: $width-max;
  min-height: calc(100vh - 138px);
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.minFlatField {
  background: $color-bg-white;
  width: 100%;
  max-width: $width-max;
  min-height: calc(100vh - 52px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.draftField {
  background: $color-white;
  width: 100%;
  height: 150px;
  max-width: $width-max;
  padding: 9px 18px 10px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;

  .draftPicker {
    margin-bottom: 10px;
    width: 60px;
    height: 4px;
    background-color: #dddddd;
    border-radius: 3px;
  }

  .draftText {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
  }
}

.exampleField {
  background: $color-bg-white;
  width: 100%;
  max-width: $width-max;
  border-radius: 20px 20px 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settingsHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 55px;
  width: 100%;
  border-bottom: 1px solid $color-gray-lighter-350;

  > a {
    padding: 20px 0;
    font-size: 14px;
  }

  &Active {
    font-weight: 700;
    border-bottom: 2px solid $color-black;
  }

  &Start {
    justify-content: flex-start;
    padding: 0 20px;

    > a + a {
      margin-left: 20px;
    }
  }
}

.button {
  width: 320px;
  height: 42px;
  border-radius: 80px;
  color: $color-white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &Small {
    width: 320px;
  }

  &Plain {
    background-color: #000000;
  }

  &Insta {
    background-color: #000000;
    background-image: url("/images/icon_instagram_white_24px.svg");
    background-size: 26px;
    background-position: 14px center;
    background-repeat: no-repeat;

    > span {
      color: $color-white;
    }
  }

  &Twitter {
    background-color: #55acee;
    background-image: url("/images/icon_twitter_white_24px.svg");
    background-size: 26px;
    background-position: 14px center;
    background-repeat: no-repeat;

    > span {
      color: $color-white;
    }
  }

  &Facebook {
    background-color: #1877f2;
    background-image: url("/images/icon_facebook.svg");
    background-size: 26px;
    background-position: 14px center;
    background-repeat: no-repeat;

    > span {
      color: $color-white;
    }
  }

  &Google {
    background-color: #fff;
    background-image: url("/images/icon_google.svg");
    background-size: 26px;
    background-position: 14px center;
    background-repeat: no-repeat;
    border: 1px solid $color-gray-lighter-320;

    > span {
      color: $color-black;
    }
  }

  &Snapchat {
    background-color: #fffc00;
    background-image: url("/images/icon_snapchat.svg");
    background-size: 26px;
    background-position: 14px center;
    background-repeat: no-repeat;

    > span {
      color: $color-black;
    }
  }

  &Send {
    //margin-top: 12px;
    justify-content: flex-start;
    padding-left: 18px;
    background-color: #000000;
    background-image: url("/images/icon_send.svg");
    background-size: 16px;
    background-position: 67px center;
    background-repeat: no-repeat;
    width: 100px;

    > span {
      color: $color-white;
    }

    &Disabled {
      color: $color-white;
      background-color: $color-gray-lighter-300;
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;

  &Contents {
    padding: 0 16px;
  }

  &Header {
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0;
  }

  .sectionTitle {
    font-size: 16px;
    font-weight: 700;

    &Danger {
      color: $color-red;
    }
  }

  .sectionDate {
    margin-top: 10px;
  }

  &Static {
    padding: 36px 26px 20px;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    h2 {
      font-size: 19px;
      font-weight: 700;
    }

    h3 {
      font-weight: 700;
    }

    h4 {
      font-weight: 700;
    }

    > ul {
      margin-top: 20px;

      > li {
        margin-left: 20px;
        margin-bottom: 10px;
        list-style-type: disc;
        list-style-position: outside;
      }
    }

    .inline {
      margin-left: 20px;
    }
  }

  .posts {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;

    .postDescribed {
      margin-bottom: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        margin: 0 4px;
      }

      > span {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .signUpSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 58px;

    .signUp {
      width: 120px;
      height: 40px;
      margin-bottom: 16px;
      flex-shrink: 0;
      color: $color-white;
      border-radius: 80px;
      text-align: center;
      background-color: $color-blue;
    }

    .login {
      color: $color-gray-darker;
    }
  }

  .staticParagraph {
    margin-top: 24px;
  }

  .staticEmail {
    margin-top: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > a {
      margin-left: 10px;
      padding-bottom: 1px;
      border-bottom: 1px solid $color-black;
    }
  }
}

.descriptionButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .descriptionButton {
    width: 100%;
    min-width: 150px;
    height: 32px;
    font-size: 13px;
    border-radius: 30px;
    padding: 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-size: 14px;
    background-position: calc(100% - 14px) center;
    background-repeat: no-repeat;

    &:only-child {
      justify-content: center;
    }

    &Decline {
      color: $color-gray;
      border: 1px solid $color-gray;
      background-image: url("/images/icon_cross.svg");
    }

    &Back {
      background-color: $color-black;
      color: $color-white;
      background-image: url("/images/icon_return.svg");
    }

    &Publish {
      background-color: $color-black;
      color: $color-white;
      background-image: url("/images/icon_send.svg");
    }

    &Delete {
      background-color: $color-white;
      color: $color-red-lighter;
      border: 1px solid $color-red-lighter;
      background-image: url("/images/icon_cross_red.svg");
    }

    &Edit {
      background-color: $color-black;
      color: $color-white;
      background-image: url("/images/icon_edit.svg");
    }
  }
}

.users {
  margin-top: 8px;
  width: 100%;
  align-items: center;

  .user {
    padding: 8px 18px;
    margin-top: 8px;
  }
}

.user {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 8px 18px;

  &Left {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &DisplayName {
    font-size: 16px;
    font-weight: 700;
    color: $color-black;
  }

  &Name {
    font-size: 13px;
    color: #aaa;
  }

  &Bio {
    font-size: 13px;
    line-height: 16px;
    margin: 12px 0;
  }

  &Avatar {
    margin-right: 6px;
  }

  &Description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &Right {
  }

  &Relation {
    padding: 6px 10px;
    background-color: $color-gray-lighter;
    border-radius: 40px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 600;
  }

  &SelectRelation {
    width: 121px;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 30px;

    background-image: url("/images/icon_arrow_down.svg");
    background-position: 98px center;
    background-repeat: no-repeat;

    > span {
      padding-left: 10px;
    }
  }
}

.bgSelectImage {
  position: relative;

  > span {
    border-radius: 5px;
  }

  .bgImageSelected {
    top: 0;
    background-color: $color-black;
    background-image: url("/images/selected.svg");
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 5px;
    opacity: 0.6;
    position: absolute;
  }
}

.askToCard {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 65px;
}

.descriptionBanner {
  width: 100%;
  margin-top: 20px;
}

.askToButton {
  width: 220px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 80px;
  background: #242be7;
  text-align: center;
  color: $color-white;
}

.systemError {
  margin: 12px 0;
  font-size: 13px;
  line-height: 18px;
  color: $color-red-lighter;
  max-width: 300px;
}

.loading {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.message {
  width: 100%;
  height: 100%;
  //padding-top: 20px;
  //background: rgba(0, 0, 0, 0.6);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    color: white;
  }

  a {
    margin-top: 20px;
    display: block;
    color: white;
    text-decoration: underline;
  }
}
